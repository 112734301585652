import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'src/components/ui/toaster';
// import { useAuth } from "src/hooks/useAuth";

// Import your page components
import MainLayout from '../../layouts/MainLayout/MainLayout';
import Login from '../../pages/Login/Login';
import { UploadResume } from '../../pages/UploadResume/UploadResume';
import { SubmitResume } from '../../pages/SubmitResume/SubmitResume';
import { CreateAccount } from '../../pages/CreateAccount/CreateAccount';
import Confirmation from '../../pages/CreateAccount/Confirmation';
import { Dashboard } from '../../pages/Dashboard/Dashboard';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import AccountActivation from '../../pages/AccountActivation/AccountActivation';
import { ResumeProfile } from '../../pages/ResumeProfile/ResumeProfile';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => (
  <>
    <Toaster />
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<MainLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<UploadResume />} />
          <Route path="/join-us" element={<UploadResume />} />
          <Route path="/submit-resume" element={<SubmitResume />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/activate-account" element={<AccountActivation />} />
          <Route path="/resume/update" element={<ResumeProfile />} />
          <Route element={<PrivateRoute />}>
            <Route path="/submit-resume" element={<SubmitResume />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </Route>
        </Route>
        {/* Protected routes */}
        {/* <Route element={<PrivateRoute />}> */}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  </>
);

export default AppRouter;
