import React, { useState, useRef, useEffect } from 'react';
import { ChevronsUpDown, X, Search, Check } from 'lucide-react';

export function MultiSelectDropdown({
  options,
  placeholder = 'Select options...',
  onChange,
  maxDisplay = 3,
  value, // Add value prop to control the component
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  // Use value prop instead of internal state for selected options
  const selectedOptions = value || [];

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const toggleOption = (option) => {
    const newSelection = selectedOptions.some(
      (item) => item.value === option.value
    )
      ? selectedOptions.filter((item) => item.value !== option.value)
      : [...selectedOptions, option];

    onChange(newSelection);
  };

  const removeOption = (option) => {
    onChange(selectedOptions.filter((item) => item.value !== option.value));
  };

  const handleKeyDown = (e, callback) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      callback();
    }
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => handleKeyDown(e, () => setIsOpen(!isOpen))}
        className="flex items-center justify-between w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        <div className="flex flex-wrap gap-1">
          {selectedOptions.length === 0 && (
            <span className="text-muted-foreground">{placeholder}</span>
          )}
          {selectedOptions.slice(0, maxDisplay).map((option) => (
            <div
              key={option.value}
              className="flex items-center bg-secondary text-secondary-foreground rounded px-1 py-0.5 text-xs"
            >
              {option.label}
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  removeOption(option);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  handleKeyDown(e, () => removeOption(option));
                }}
                className="ml-1 text-secondary-foreground hover:text-primary cursor-pointer"
                aria-label={`Remove ${option.label}`}
              >
                <X size={12} />
              </button>
            </div>
          ))}
          {selectedOptions.length > maxDisplay && (
            <span className="text-xs text-muted-foreground">
              +{selectedOptions.length - maxDisplay} more
            </span>
          )}
        </div>
        <ChevronsUpDown className="h-4 w-4 opacity-50" />
      </div>

      {isOpen && (
        <div
          className="absolute mt-1 w-full rounded-md border bg-popover text-popover-foreground shadow-md z-50"
          role="listbox"
          aria-label="Options list"
        >
          <div className="flex items-center border-b px-3 py-2">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              ref={searchInputRef}
              type="text"
              placeholder="Search options..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex h-9 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
              aria-label="Search options"
            />
          </div>
          <div className="max-h-[300px] overflow-auto">
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                role="option"
                tabIndex={0}
                aria-selected={selectedOptions.some(
                  (item) => item.value === option.value
                )}
                onClick={() => toggleOption(option)}
                onKeyDown={(e) => handleKeyDown(e, () => toggleOption(option))}
                className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              >
                <div className="mr-2 h-4 w-4 rounded-sm border border-primary flex items-center justify-center">
                  {selectedOptions.some(
                    (item) => item.value === option.value
                  ) && <Check className="h-3 w-3 text-primary" />}
                </div>
                <span>{option.label}</span>
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className="py-6 text-center text-sm">No results found.</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
