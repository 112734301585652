import React from 'react';
import { PlusIcon } from 'lucide-react';
import { Button } from 'src/components/ui/button';

export const FormField = ({
  label,
  children,
  error = null,
  id = undefined,
}) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {React.cloneElement(children, { id })}
    {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
  </div>
);

export const Section = ({ title, children }) => (
  <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    {children}
  </div>
);

export const FormActions = () => (
  <div className="flex justify-end items-center mt-6">
    <Button type="submit">Submit Application</Button>
  </div>
);

export const AddButton = ({
  onClick,
  text,
  variant = 'default',
  size = 'sm',
  className = 'flex items-center gap-2',
}) => (
  <Button
    type="button"
    onClick={onClick}
    variant={variant}
    size={size}
    className={className}
  >
    <PlusIcon className="h-4 w-4" />
    {text}
  </Button>
);
