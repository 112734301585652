import React from 'react';
import { Check } from 'lucide-react';
import { OTPInput } from './OTPInput';

const OTPVerificationSection = ({
  isVerifyingOTP,
  handleVerifyOTP,
  otpError,
  canResend,
  resendTimeout,
  handleSendOTP,
  contactVerified,
}) => (
  <div className="mt-2">
    <div className="flex justify-between items-center mb-1">
      <p className="text-sm text-gray-600">Enter Contact OTP</p>
      {isVerifyingOTP && (
        <span className="text-sm text-blue-600">Verifying...</span>
      )}
    </div>
    <OTPInput
      onChange={handleVerifyOTP}
      disabled={isVerifyingOTP || contactVerified}
      length={4}
    />
    {otpError && <p className="mt-1 text-xs text-red-500">{otpError}</p>}
    <div className="mt-2 flex justify-between items-center">
      <button
        type="button"
        onClick={() => handleSendOTP('RESEND')}
        disabled={!canResend || isVerifyingOTP || contactVerified}
        className={`text-sm ${
          canResend && !contactVerified
            ? 'text-blue-600 hover:text-blue-800'
            : 'text-gray-400'
        }`}
      >
        {canResend ? 'Resend OTP' : `Resend OTP in ${resendTimeout}s`}
      </button>
      {contactVerified && (
        <span className="text-sm text-green-600 flex items-center">
          <Check className="w-4 h-4 mr-1" />
          Verified
        </span>
      )}
    </div>
  </div>
);

export default OTPVerificationSection;
