import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';

// FormField Component for consistency
const FormField = ({ label, children, error, id }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {React.cloneElement(children, { id })}
    {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
  </div>
);

export function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.formData?.email || '';

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email,
      password: '',
      confirmPassword: '',
    },
  });

  const password = watch('password');

  const registerField = (name, options) => {
    const { ref, ...rest } = register(name, options);
    return {
      name,
      ref,
      ...rest,
    };
  };

  const onSubmit = async (data) => {
    try {
      console.log('Account created:', data);
      // Here you would typically make an API call to create the account
      navigate('/dashboard');
    } catch (error) {
      console.error('Account creation error:', error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-green-600 mb-2">
            Congratulations!
          </h2>
          <h3 className="text-xl font-semibold">Create Account</h3>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {/* Email Field - Autofilled and readonly */}
          <FormField label="Email" error={errors.email?.message} id="email">
            <Input
              type="email"
              name="email"
              readOnly
              className="bg-gray-50"
              onChange={(e) =>
                registerField('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email format',
                  },
                }).onChange(e)
              }
              onBlur={(e) => registerField('email').onBlur(e)}
            />
          </FormField>

          {/* Confirm Password Field */}
          <FormField
            label="Confirm Password"
            error={errors.confirmPassword?.message}
            id="confirmPassword"
          >
            <Input
              type="password"
              name="confirmPassword"
              onChange={(e) =>
                registerField('confirmPassword', {
                  required: 'Please confirm your password',
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                }).onChange(e)
              }
              onBlur={(e) => registerField('confirmPassword').onBlur(e)}
            />
          </FormField>

          <div className="mt-2 text-sm text-gray-600">
            Password must contain:
            <ul className="list-disc list-inside mt-1">
              <li>At least 8 characters</li>
              <li>One uppercase letter</li>
              <li>One lowercase letter</li>
              <li>One number</li>
              <li>One special character (@$!%*?&)</li>
            </ul>
          </div>

          <Button type="submit" className="w-full mt-6">
            Create Account
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Dashboard;
