import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Alert, AlertDescription } from 'src/components/ui/alert';
import { UserService } from 'src/services/api/UserService';

const FormField = ({ label, children, error, id }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {React.cloneElement(children, { id })}
    {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
  </div>
);

const passwordValidation = {
  required: 'Password is required',
  minLength: {
    value: 8,
    message: 'Password must be at least 8 characters',
  },
  pattern: {
    value:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    message:
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
  },
};

export function CreateAccount() {
  const navigate = useNavigate();
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Separated data retrieval function
  const getUserData = () => {
    try {
      const userDataString = localStorage.getItem('userData');
      return userDataString ? JSON.parse(userDataString) : null;
    } catch (error) {
      console.error('Error getting user data:', error);
      return null;
    }
  };

  const userData = getUserData();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: userData?.email || '',
      password: '',
      confirmPassword: '',
    },
  });

  const watchedPassword = watch('password');

  useEffect(() => {
    if (!userData) {
      navigate('/join-us');
      return () => {}; // Return empty cleanup function
    }

    const handleClickOutside = (event) => {
      const isPasswordField = event.target.name === 'password';
      if (!isPasswordField) {
        setShowPasswordTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [userData, navigate]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      setApiError('');

      const registrationData = {
        email: data.email,
        password: data.password,
        firstName: userData.firstName,
        lastName: userData.lastName,
        cid: userData.cid.toString(),
      };

      const response = await UserService.registerUser(registrationData);
      console.log('response reg', response);

      if (response.success) {
        localStorage.removeItem('userData');
        navigate('/confirmation');
      }
    } catch (error) {
      console.error('Account creation error:', error);

      if (error.response?.status === 409) {
        setError('email', {
          type: 'manual',
          message: 'This email is already registered',
        });
      } else if (error.response?.status === 400) {
        const serverErrors = error.response.data.errors;
        if (serverErrors) {
          Object.keys(serverErrors).forEach((key) => {
            setError(key, {
              type: 'manual',
              message: serverErrors[key],
            });
          });
        }
      } else {
        setApiError(
          error.response?.data?.message ||
            'An error occurred during registration. Please try again.'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const registerInput = (name, options = {}) => {
    const { onChange, onBlur, ref, name: fieldName } = register(name, options);
    return {
      onChange,
      onBlur,
      name: fieldName,
      ref,
    };
  };

  if (!userData) {
    return null;
  }

  return (
    <div className="grid place-items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mx-auto text-left">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold ahBlueText mb-2">
            Congratulations!
          </h2>
          <h3 className="text-xl font-semibold">Create Account</h3>
        </div>

        {apiError && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{apiError}</AlertDescription>
          </Alert>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        >
          <div style={{ display: 'none' }}>
            <input type="text" name="fakeusernameremembered" />
            <input type="password" name="fakepasswordremembered" />
          </div>

          <FormField label="Email" error={errors.email?.message} id="email">
            <Input
              type="email"
              readOnly
              className="bg-gray-50"
              autoComplete="chrome-off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              {...registerInput('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email format',
                },
              })}
            />
          </FormField>

          <FormField
            label="Password"
            error={errors.password?.message}
            id="password"
          >
            <TooltipProvider>
              <Tooltip open={showPasswordTooltip}>
                <TooltipTrigger asChild>
                  <div className="relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      onFocus={() => setShowPasswordTooltip(true)}
                      autoComplete="chrome-off"
                      data-lpignore="true"
                      data-form-type="other"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      aria-autocomplete="none"
                      {...registerInput('password', passwordValidation)}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="p-2">
                  <div className="text-sm">
                    Password must contain:
                    <div className="space-y-1 mt-1">
                      <div>• At least 8 characters</div>
                      <div>• One uppercase letter</div>
                      <div>• One lowercase letter</div>
                      <div>• One number</div>
                      <div>• One special character (@$!%*?&)</div>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </FormField>

          <FormField
            label="Confirm Password"
            error={errors.confirmPassword?.message}
            id="confirmPassword"
          >
            <div className="relative">
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                autoComplete="new-password"
                data-lpignore="true"
                data-form-type="other"
                {...registerInput('confirmPassword', {
                  required: 'Please confirm your password',
                  validate: (value) =>
                    value === watchedPassword || 'Passwords do not match',
                })}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
          </FormField>

          <Button type="submit" className="w-full mt-6" disabled={isLoading}>
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CreateAccount;
