import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { useAuthActions } from 'src/hooks/useAuthActions';
import { useToast } from 'src/hooks/use-toast';
import loginImg from '../../assets/images/login_img.png';

const Login = () => {
  const { login } = useAuthActions();
  const { toast } = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      setApiError('');

      const loginData = {
        email,
        password,
      };

      await login(loginData);
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage =
        error.message || 'An error occurred during login. Please try again.';
      setApiError(errorMessage);

      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      {/* Left Column - Image */}
      <div className="hidden md:block w-[68%] relative">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${loginImg})`,
            opacity: '0.3',
          }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-30" />
        <div className="relative z-10 h-full flex flex-col justify-center px-16">
          <h1 className="text-4xl font-bold mb-4 ahDarkBlueText">
            Welcome Back to Our Platform
          </h1>
          <p className="font-medium text-gray-800 px-8">
            Sign in to access your account and continue your journey with us.
            We&apos;re here to help you succeed in your career goals.
          </p>
        </div>
      </div>

      {/* Right Column - Form */}
      <div className="w-full md:w-[32%] flex justify-center items-center p-6 overflow-y-auto">
        <div className="w-full max-w-lg ">
          <h2 className="text-2xl font-bold mb-8">Login</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {apiError && (
              <div className="rounded-md bg-destructive/15 px-4 py-3 text-sm text-destructive">
                {apiError}
              </div>
            )}

            <div className="space-y-4">
              <Input
                type="email"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                className={`h-12 ${apiError ? 'border-red-500' : ''}`}
              />

              <div className="space-y-1">
                <Input
                  type="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                  className={`h-12 ${apiError ? 'border-red-500' : ''}`}
                />
                <div className="flex justify-end">
                  <Link
                    to="/forgot-password"
                    className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <Button
                type="submit"
                className="w-full h-12 text-base"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                    Logging in...
                  </div>
                ) : (
                  'Login'
                )}
              </Button>
            </div>

            {/* OAuth Divider */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-white px-2 text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            {/* OAuth Buttons */}
            <div className="grid grid-cols-2 gap-4">
              <Button
                type="button"
                variant="outline"
                disabled={isLoading}
                className="h-12"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="mr-2 h-5 w-5"
                >
                  <path
                    fill="#EA4335"
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  />
                  <path
                    fill="#4285F4"
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  />
                  <path
                    fill="#34A853"
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  />
                </svg>
                Google
              </Button>
              <Button
                type="button"
                variant="outline"
                disabled={isLoading}
                className="h-12"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="mr-2 h-5 w-5"
                  fill="#0A66C2"
                >
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.225 0z" />
                </svg>
                LinkedIn
              </Button>
            </div>

            <div className="text-sm text-gray-600 text-center">
              Don&apos;t have an account?{' '}
              <Link
                to="/join-us"
                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
              >
                Join Us
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
