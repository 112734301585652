import { useState, useEffect } from 'react';
import { ResumeService } from 'src/services/api/ResumeService';

export const useOTP = (contactValue, toast) => {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [contactVerified, setContactVerified] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [resendTimeout, setResendTimeout] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let timer;
    if (isOTPSent && resendTimeout > 0) {
      timer = setInterval(() => {
        setResendTimeout((prev) => prev - 1);
      }, 1000);
    }
    if (resendTimeout === 0) {
      setCanResend(true);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isOTPSent, resendTimeout]);

  const handleSendOTP = async (type = 'SEND') => {
    try {
      setIsOTPLoading(true);
      const otpData = { phone: contactValue };
      const response = await (type === 'SEND'
        ? ResumeService.sendOtp(otpData)
        : ResumeService.resendOtp(otpData));

      if (response.success) {
        setIsOTPSent(true);
        setShowOTPInput(true);
        if (type === 'RESEND') {
          setCanResend(false);
          setResendTimeout(30);
        }
        toast({
          title: `OTP ${type === 'SEND' ? 'Sent' : 'Resent'} Successfully`,
          description: 'Please check your phone for the OTP',
        });
      } else {
        throw new Error(response.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('OTP sending error:', error);
      toast({
        title: 'Failed to Send OTP',
        description: error.message || 'Please try again later',
        variant: 'destructive',
      });
      setIsOTPSent(false);
      setShowOTPInput(false);
    } finally {
      setIsOTPLoading(false);
    }
  };

  const handleVerifyOTP = async (otp) => {
    if (otp.length === 4) {
      try {
        setIsVerifyingOTP(true);
        setOtpError('');
        const verificationData = { phone: contactValue, otp };
        const response = await ResumeService.verifyOtp(verificationData);

        if (response.success) {
          setContactVerified(true);
          setShowOTPInput(false);
          toast({
            title: 'Phone Verified',
            description: 'Your phone number has been verified successfully.',
          });
        } else {
          throw new Error(response.message || 'Invalid OTP');
        }
      } catch (error) {
        console.error('OTP verification error:', error);
        setContactVerified(false);
        setOtpError(error.message || 'Failed to verify OTP. Please try again.');
        toast({
          title: 'Verification Failed',
          description: error.message || 'Please check your OTP and try again',
          variant: 'destructive',
        });
      } finally {
        setIsVerifyingOTP(false);
      }
    }
  };

  return {
    showOTPInput,
    contactVerified,
    isOTPSent,
    isOTPLoading,
    isVerifyingOTP,
    otpError,
    resendTimeout,
    canResend,
    handleSendOTP,
    handleVerifyOTP,
  };
};
