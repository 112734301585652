export const createEndpoint = (template, params = {}) =>
  Object.entries(params).reduce(
    (endpoint, [key, value]) => endpoint.replace(`:${key}`, value),
    template
  );

export const API_ENDPOINTS = {
  AUTH: {
    VERIFY: '/auth/verify',
    REFRESH: '/auth/refresh',
  },
  USER: {
    REGISTER: '/auth/register',
    LOGIN: '/auth/login',
    PROFILE: '/user/profile',
    UPDATE: '/user/update',
    ACTIVATE: '/auth/verify-email/:token',
  },
  RESUME: {
    SENDOTP: '/candidates/request-otp',
    VERIFYOTP: '/candidates/verify-otp',
    RESENDOTP: '/candidates/resend-otp',
    REQUESTSIGNEDURL: '/util/generate-upload-url',
    FORMSUBMISSION: '/candidates/submit-basic-profile',
    GETPROFILE: '/candidates/:id/profile',
    UPDATEPROFILE: '/candidates/:id/completeprofile',
    RESUMEPROFILE: 'candidates/resume-profile/verify/:token',
  },
  // Add more endpoints as needed
};
