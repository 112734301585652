import React from 'react';
import { Input } from 'src/components/ui/input';
import { Check } from 'lucide-react';
import { FormField } from 'src/components/common/FormField';

export const ResumeUpload = ({
  register,
  errors,
  contactVerified,
  fileUploadError,
  handleFileSelection,
  validateFile,
  isUploadReady,
}) => {
  const registerProps = register('resume', {
    required: 'Resume is required',
    validate: validateFile,
    onChange: handleFileSelection,
  });

  const getUploadStatusMessage = () => {
    if (!contactVerified) return '';
    if (isUploadReady) return 'Resume ready to upload';
    return 'Upload your resume (PDF, DOC, or DOCX format, max 5MB).';
  };

  return (
    <FormField
      label="Resume"
      error={errors.resume?.message || fileUploadError}
      id="resume"
    >
      <div className={`relative ${!contactVerified ? 'opacity-50' : ''}`}>
        <Input
          type="file"
          accept=".pdf,.doc,.docx"
          disabled={!contactVerified}
          id="resume"
          onChange={registerProps.onChange}
          onBlur={registerProps.onBlur}
          name={registerProps.name}
          ref={registerProps.ref}
          className={`
            ${errors.resume || fileUploadError ? 'border-red-500' : ''} 
            cursor-pointer
            ${!contactVerified ? 'cursor-not-allowed' : ''}
          `}
        />
        {isUploadReady && (
          <div className="absolute right-2 top-1/2 -translate-y-1/2">
            <Check className="h-5 w-5 text-green-500" />
          </div>
        )}
      </div>
      <p className="mt-1 text-xs text-gray-500">{getUploadStatusMessage()}</p>
    </FormField>
  );
};
