import React from 'react';
import { Controller } from 'react-hook-form';
import { MultiSelectDropdown } from 'src/components/common/MultiSelectDropdown';
// import { Input } from 'src/components/ui/input';

const Section = ({ title, children }) => (
  <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    {children}
  </div>
);

const FormField = ({ label, children, id }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {React.cloneElement(children, { id })}
  </div>
);

const transformToOptions = (items) =>
  items?.map((item) => ({
    value: item.id.toString(),
    label: item.name,
  })) || [];

export function SkillsSection({ control, masterData }) {
  const skillsId = 'skills-select';
  // const otherSkillsId = 'other-skills';

  const skillOptions = React.useMemo(
    () => transformToOptions(masterData?.skills),
    [masterData?.skills]
  );

  const defaultSelectedSkills = React.useMemo(() => {
    const skillIds =
      masterData?.profile_data?.skills?.map((skill) => skill.id.toString()) ||
      [];
    return skillOptions.filter((option) => skillIds.includes(option.value));
  }, [masterData?.profile_data?.skills, skillOptions]);

  return (
    <Section title="Skills">
      <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50 transition-all duration-200">
        <FormField label="Select your skills" id={skillsId}>
          <Controller
            name="skills"
            control={control}
            defaultValue={defaultSelectedSkills.map((skill) => skill.value)}
            render={({ field: { onChange, value = [] } }) => (
              <MultiSelectDropdown
                options={skillOptions}
                placeholder="Select skills..."
                onChange={(selected) => {
                  onChange(selected.map((item) => item.value));
                }}
                maxDisplay={3}
                value={skillOptions.filter((option) =>
                  value?.includes(option.value)
                )}
              />
            )}
          />
        </FormField>

        {/* <FormField label="Other skills (if any)" id={otherSkillsId}>
          <Controller
            name="otherSkills"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter other skills, separated by commas"
              />
            )}
          />
        </FormField> */}
      </div>
    </Section>
  );
}
