import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from 'src/components/ui/alert';
import { UserService } from 'src/services/api/UserService';

const AccountActivation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('activating'); // 'activating', 'success', 'error'
  const [error, setError] = useState('');

  useEffect(() => {
    const activateAccount = async () => {
      const token = searchParams.get('token');

      if (!token) {
        setStatus('error');
        setError('Activation token is missing');
        return;
      }

      try {
        const response = await UserService.activateAccount(token);

        if (!response.success) {
          throw new Error('Account activation failed');
        }

        setStatus('success');
        // Redirect to login page after 2 seconds
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } catch (err) {
        setStatus('error');
        setError(err.message || 'Failed to activate account');
      }
    };

    activateAccount();
  }, [searchParams, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      {status === 'activating' && (
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <p className="text-lg">Activating your account...</p>
        </div>
      )}

      {status === 'success' && (
        <Alert className="max-w-md" variant="default">
          <AlertTitle>Success!</AlertTitle>
          <AlertDescription>
            Your account has been successfully activated. Redirecting to login
            page...
          </AlertDescription>
        </Alert>
      )}

      {status === 'error' && (
        <Alert className="max-w-md" variant="destructive">
          <AlertTitle>Activation Failed</AlertTitle>
          <AlertDescription>
            {error}. Please try again or contact support.
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default AccountActivation;
