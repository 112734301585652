import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { CalendarIcon } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { minEducations, boardOptions } from './constants';
import { AddButton } from './common';

const SelectField = ({ label, id, children }) => (
  <div className="space-y-2">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="relative">
      {React.cloneElement(children, {
        'aria-labelledby': id,
        id,
      })}
    </div>
  </div>
);

const InputField = ({ id, label, children }) => (
  <div className="space-y-2">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    {React.cloneElement(children, { id })}
  </div>
);

const GraduationYearField = ({ index, control, errors }) => {
  const fieldId = `graduation-year-${index}`;
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1980 + 1 },
    (_, i) => currentYear - i
  ).reverse();

  return (
    <SelectField label="Graduation Year" id={fieldId}>
      <Controller
        name={`educations.${index}.graduationYear`}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <SelectTrigger
              className={`w-full ${
                errors?.educations?.[index]?.graduationYear
                  ? 'border-red-500 focus:border-red-500'
                  : ''
              }`}
            >
              <div className="flex items-center">
                <CalendarIcon className="mr-2 h-4 w-4" />
                <SelectValue placeholder="Select year" />
              </div>
            </SelectTrigger>
            <SelectContent>
              {years.map((year) => (
                <SelectItem key={year} value={year.toString()}>
                  {year}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </SelectField>
  );
};

const QualificationField = ({ index, control, errors }) => {
  const fieldId = `qualification-${index}`;

  const getQualificationLabel = (value) => {
    const qualification = minEducations.find((edu) => edu.value === value);
    return qualification?.label || '';
  };

  return (
    <SelectField label="Qualification" id={fieldId}>
      <Controller
        name={`educations.${index}.qualification`}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select onValueChange={field.onChange} defaultValue='"TWELTH"'>
            <SelectTrigger
              className={`${
                errors?.educations?.[index]?.qualification
                  ? 'border-red-500 focus:border-red-500'
                  : ''
              }`}
            >
              <SelectValue placeholder="Select Qualification">
                {getQualificationLabel(field.value)}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {minEducations.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </SelectField>
  );
};

const FieldOfStudyField = ({
  index,
  control,
  errors,
  qualification,
  masterData,
}) => {
  const fieldId = `field-of-study-${index}`;

  // Get appropriate options based on qualification
  const getFieldOptions = () => {
    // if (qualification === 'TWELTH') {
    //   return masterData.fieldsofstudieshsc || [];
    // }
    if (
      qualification === 'TWELTH' ||
      qualification === 'DEGREE' ||
      qualification === 'MASTERS'
    ) {
      return masterData.fieldsofstudies || [];
    }
    return [];
  };

  const isDisabled = qualification === 'TENTH';

  return (
    <SelectField label="Field of Study" id={fieldId}>
      <Controller
        name={`educations.${index}.fieldsOfStudy`}
        control={control}
        rules={{ required: !isDisabled }}
        render={({ field }) => (
          <Select
            onValueChange={field.onChange}
            defaultValue={field.value}
            disabled={isDisabled}
          >
            <SelectTrigger
              className={`${
                errors?.educations?.[index]?.fieldsOfStudy
                  ? 'border-red-500 focus:border-red-500'
                  : ''
              }`}
            >
              <SelectValue placeholder="Select Field of Study" />
            </SelectTrigger>
            <SelectContent>
              {getFieldOptions().map((option) => (
                <SelectItem key={option.id} value={option.id.toString()}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </SelectField>
  );
};

const RadioButton = ({ id, value, checked, onChange, label }) => (
  <div className="flex items-center space-x-2 cursor-pointer">
    <input
      type="radio"
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      className="w-4 h-4 text-primary border-gray-300 focus:ring-primary"
    />
    <label
      htmlFor={id}
      className="text-sm font-medium text-gray-700 cursor-pointer"
    >
      {label}
    </label>
  </div>
);

const EarnedGPASelect = React.memo(
  ({ field, errors, index, maxGPA, onChange }) => {
    const earnedGPAValue = field.value ? `${field.value}.0` : undefined;

    return (
      <Select
        onValueChange={onChange}
        value={earnedGPAValue}
        defaultValue={earnedGPAValue}
      >
        <SelectTrigger
          className={`${
            errors?.educations?.[index]?.earnedGPA
              ? 'border-red-500 focus:border-red-500'
              : ''
          }`}
        >
          <SelectValue placeholder="Earned GPA" />
        </SelectTrigger>
        <SelectContent>
          {Array.from(
            {
              length: parseFloat(maxGPA) * 10 || 0,
            },
            (_, i) => ({
              value: (i / 10).toFixed(1),
              label: (i / 10).toFixed(1),
            })
          ).map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }
);

EarnedGPASelect.displayName = 'EarnedGPASelect';

const GradeTypeSelector = ({
  index,
  onGradeTypeChange,
  control,
  watch,
  errors,
}) => {
  const currentGradeType = watch(
    `educations.${index}.gradeType`
  )?.toLowerCase();

  const gpaOptions = [
    { value: '5.0', label: '5.0' },
    { value: '10.0', label: '10.0' },
  ];
  const maxGPAValue = watch(`educations.${index}.maxGPA`);
  // Format it to match options format
  const formattedMaxGPA = maxGPAValue ? `${maxGPAValue}.0` : undefined;
  console.log('formattedMaxGPA ', formattedMaxGPA);

  return (
    <>
      <div className="flex items-center justify-start gap-12 mb-2">
        <RadioButton
          id={`marks-${index}`}
          value="marks"
          checked={currentGradeType === 'marks'}
          onChange={(e) => onGradeTypeChange(e.target.value)}
          label="Marks"
        />
        <RadioButton
          id={`gpa-${index}`}
          value="gpa"
          checked={currentGradeType === 'gpa'}
          onChange={(e) => onGradeTypeChange(e.target.value)}
          label="GPA"
        />
      </div>

      <div className="max-w-xs">
        {currentGradeType === 'marks' ? (
          <div className="grid grid-cols-2 gap-4">
            <Controller
              name={`educations.${index}.maxMarks`}
              control={control}
              rules={{
                required: true,
                min: 0,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  placeholder="Max Marks"
                  className={
                    errors?.educations?.[index]?.maxMarks
                      ? 'border-red-500'
                      : ''
                  }
                />
              )}
            />
            <Controller
              name={`educations.${index}.earnedMarks`}
              control={control}
              rules={{
                required: true,
                min: 0,
                validate: (value) => {
                  const maxMarks = watch(`educations.${index}.maxMarks`);
                  return !maxMarks || parseFloat(value) <= parseFloat(maxMarks);
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  placeholder="Earned Marks"
                  className={
                    errors?.educations?.[index]?.earnedMarks
                      ? 'border-red-500'
                      : ''
                  }
                />
              )}
            />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            <Controller
              name={`educations.${index}.maxGPA`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  value={formattedMaxGPA}
                  defaultValue={formattedMaxGPA}
                >
                  <SelectTrigger
                    className={`${
                      errors?.educations?.[index]?.maxGPA
                        ? 'border-red-500 focus:border-red-500'
                        : ''
                    }`}
                  >
                    <SelectValue placeholder="Max GPA" />
                  </SelectTrigger>
                  <SelectContent>
                    {gpaOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
            <Controller
              name={`educations.${index}.earnedGPA`}
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const maxGPA = watch(`educations.${index}.maxGPA`);
                  return !maxGPA || parseFloat(value) <= parseFloat(maxGPA);
                },
              }}
              render={({ field }) => (
                <EarnedGPASelect
                  field={field}
                  errors={errors}
                  index={index}
                  maxGPA={maxGPAValue}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        )}
      </div>
    </>
  );
};
const BoardUniversityField = ({ index, control, errors, qualification }) => {
  const isSchoolLevel = qualification === 'TENTH' || qualification === 'TWELTH';
  const fieldId = `board-university-${index}`;

  if (isSchoolLevel) {
    return (
      <SelectField label="Board" id={fieldId}>
        <Controller
          name={`educations.${index}.board`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <SelectTrigger
                className={`${
                  errors?.educations?.[index]?.board
                    ? 'border-red-500 focus:border-red-500'
                    : ''
                }`}
              >
                <SelectValue placeholder="Select Board" />
              </SelectTrigger>
              <SelectContent>
                {boardOptions.map((option) => (
                  <SelectItem key={option.id} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </SelectField>
    );
  }

  return (
    <InputField id={fieldId} label="University">
      <Controller
        name={`educations.${index}.university`}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Enter university name"
            className={`${
              errors?.educations?.[index]?.university
                ? 'border-red-500 focus:border-red-500'
                : ''
            }`}
          />
        )}
      />
    </InputField>
  );
};

export const EducationFields = ({
  index,
  control,
  watch,
  onRemove,
  errors,
  education,
  setValue,
  masterData,
}) => {
  const qualification = watch(`educations.${index}.qualification`);

  // Initialize grade type from education prop or default to 'marks'
  const [gradeType, setGradeType] = React.useState(
    education?.gradeType?.toLowerCase() || 'marks'
  );

  React.useEffect(() => {
    setValue(`educations.${index}.gradeType`, gradeType.toUpperCase());
  }, []);

  // Update the field name to store grade type in the form
  const handleGradeTypeChange = (value) => {
    setGradeType(value);
    setValue(`educations.${index}.gradeType`, value.toUpperCase());

    // Clear the other type's values
    if (value === 'marks') {
      setValue(`educations.${index}.maxGPA`, '');
      setValue(`educations.${index}.earnedGPA`, '');
    } else {
      setValue(`educations.${index}.maxMarks`, '');
      setValue(`educations.${index}.earnedMarks`, '');
    }
  };
  const institutionId = `institution-${index}`;

  return (
    <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50">
      <div className="grid grid-cols-12 gap-x-4 gap-y-1">
        <div className="col-span-12 md:col-span-8">
          <InputField id={institutionId} label="Institution">
            <Controller
              name={`educations.${index}.institution`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter institution name"
                  className={`${
                    errors?.educations?.[index]?.institution
                      ? 'border-red-500 focus:border-red-500'
                      : ''
                  }`}
                />
              )}
            />
          </InputField>
        </div>
        <div className="col-span-12 md:col-span-4">
          <GraduationYearField
            index={index}
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-x-4 gap-y-1 mt-4">
        <div className="col-span-12 md:col-span-4">
          <QualificationField index={index} control={control} errors={errors} />
        </div>
        <div className="col-span-12 md:col-span-4">
          <FieldOfStudyField
            index={index}
            control={control}
            errors={errors}
            qualification={qualification}
            masterData={masterData}
          />
        </div>
        {qualification && (
          <div className="col-span-12 md:col-span-4">
            <BoardUniversityField
              index={index}
              control={control}
              errors={errors}
              qualification={qualification}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 gap-x-4 gap-y-1 mt-4">
        <div className="col-span-12 md:col-span-4">
          <GradeTypeSelector
            index={index}
            onGradeTypeChange={handleGradeTypeChange}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
          />
        </div>
        <div className="col-span-12 md:col-span-8 mt-4">
          {onRemove && (
            <div className="flex justify-end mt-4">
              <Button
                type="button"
                onClick={onRemove}
                size="sm"
                variant="outline"
                className="text-red-500 border-red-500 hover:bg-red-50"
              >
                <span className="text-sm">Remove Education</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const AddEducationButton = ({ onClick }) => (
  <AddButton onClick={onClick} text="Add Education" />
);
