import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { useToast } from 'src/hooks/use-toast';
import { ResumeService } from 'src/services/api/ResumeService';
import { FormField } from 'src/components/common/FormField';
import { useOTP } from 'src/hooks/useOTP';
import { useResumeUpload } from 'src/hooks/useResumeUpload';
import leftbg from 'src/assets/images/leftbg_img.png';
import { ContactVerification } from './ContactVerification';
import { ResumeUpload } from './ResumeUpload';

export function UploadResume() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      contact: '',
      resume: null,
    },
  });

  const contactValue = watch('contact');
  const otpHook = useOTP(contactValue, toast);
  const resumeHook = useResumeUpload();
  // const resumeHook = useResumeUpload({ contact: contactValue });

  const onSubmit = async (data) => {
    try {
      if (!otpHook.contactVerified) {
        setSubmitError('Please verify your contact number first');
        return;
      }

      if (!resumeHook.selectedFile) {
        setSubmitError('Please select a resume file');
        return;
      }

      setIsSubmitting(true);
      setSubmitError('');

      // Upload resume and get URL
      const resumeUrl = await resumeHook.uploadResume(data.contact);

      const formSubmitData = {
        first_name: data.firstname,
        mid_name: data.middlename,
        last_name: data.lastname,
        email: data.email,
        phone: data.contact,
        resume_url: resumeUrl,
      };

      const response = await ResumeService.submitForm(formSubmitData);

      if (response.success) {
        const userData = {
          email: data.email,
          firstName: data.firstname,
          lastName: data.lastname,
          cid: response.data.id,
        };

        // Save the object to localStorage
        localStorage.setItem('userData', JSON.stringify(userData));
        toast({
          title: 'Success',
          description: 'Your profile has been submitted successfully',
        });

        navigate('/create-account');
      } else {
        throw new Error(response.message || 'Failed to submit form');
      }
    } catch (error) {
      setSubmitError(error.message);
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormFields = () => (
    <>
      <FormField
        label="First Name"
        error={errors.firstname?.message}
        id="firstname"
      >
        <Input
          id="firstname"
          {...register('firstname', {
            required: 'First Name is required',
            minLength: {
              value: 2,
              message: 'First Name must be at least 2 characters',
            },
            pattern: {
              value: /^[A-Za-z]+$/,
              message: 'First Name must contain only letters',
            },
          })}
          className={errors.firstname ? 'border-red-500' : ''}
          aria-invalid={errors.firstname ? 'true' : 'false'}
        />
      </FormField>

      <FormField
        label="Middle Name"
        error={errors.middlename?.message}
        id="middlename"
      >
        <Input
          id="middlename"
          {...register('middlename', {
            pattern: {
              value: /^[A-Za-z]*$/,
              message: 'Middle Name must contain only letters',
            },
          })}
          className={errors.middlename ? 'border-red-500' : ''}
          aria-invalid={errors.middlename ? 'true' : 'false'}
        />
      </FormField>

      <FormField
        label="Last Name"
        error={errors.lastname?.message}
        id="lastname"
      >
        <Input
          id="lastname"
          {...register('lastname', {
            required: 'Last Name is required',
            pattern: {
              value: /^[A-Za-z]+$/,
              message: 'Last Name must contain only letters',
            },
          })}
          className={errors.lastname ? 'border-red-500' : ''}
          aria-invalid={errors.lastname ? 'true' : 'false'}
        />
      </FormField>

      <FormField label="Email" error={errors.email?.message} id="email">
        <Input
          id="email"
          type="email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email format',
            },
          })}
          className={errors.email ? 'border-red-500' : ''}
          aria-invalid={errors.email ? 'true' : 'false'}
        />
      </FormField>
    </>
  );

  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      <div className="hidden md:block w-[68%] relative">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${leftbg})`, opacity: '0.3' }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-30" />
        <div className="relative z-10 h-full flex flex-col justify-center px-16">
          <h1 className="text-4xl font-bold mb-4 ahDarkBlueText">
            Empowering Talent in Risk and Compliance
          </h1>
          <p className="font-medium text-gray-800 px-8">
            Ahedge is dedicated to providing top-tier talent solutions and
            comprehensive training and certification programs. We help banks and
            fintechs secure the right talent and enhance their team&apos;s
            skills in the risk and compliance domain.
          </p>
        </div>
      </div>

      <div className="w-full md:w-[32%] flex justify-center items-center p-6 overflow-y-auto">
        <div className="w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center">Join Us Today</h2>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-2 text-left"
            noValidate
          >
            {renderFormFields()}

            <ContactVerification
              register={register}
              errors={errors}
              contactValue={contactValue}
              contactVerified={otpHook.contactVerified}
              isOTPSent={otpHook.isOTPSent}
              isOTPLoading={otpHook.isOTPLoading}
              showOTPInput={otpHook.showOTPInput}
              isVerifyingOTP={otpHook.isVerifyingOTP}
              otpError={otpHook.otpError}
              canResend={otpHook.canResend}
              resendTimeout={otpHook.resendTimeout}
              handleSendOTP={otpHook.handleSendOTP}
              handleVerifyOTP={otpHook.handleVerifyOTP}
            />

            <ResumeUpload
              register={register}
              errors={errors}
              contactVerified={otpHook.contactVerified}
              isFileUploading={resumeHook.isFileUploading}
              isUploadReady={resumeHook.isUploadReady}
              fileUploadError={resumeHook.fileUploadError}
              handleFileSelection={resumeHook.handleFileSelection}
              validateFile={resumeHook.validateFile}
            />

            {submitError && (
              <p className="text-red-500 text-sm mt-2">{submitError}</p>
            )}

            <Button
              type="submit"
              className="w-full mt-4"
              disabled={
                !otpHook.contactVerified ||
                !resumeHook.isUploadReady ||
                isSubmitting
              }
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                  <span>Submitting...</span>
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UploadResume;
