import api from '../axios.config';
import { API_ENDPOINTS, createEndpoint } from './endpoints';

export const ResumeService = {
  sendOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.RESUME.SENDOTP, data);
    return response;
  },

  verifyOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.RESUME.VERIFYOTP, data);
    return response;
  },
  resendOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.RESUME.RESENDOTP, data);
    return response;
  },
  getSignedUrl: async (fileData) => {
    const response = await api.post(
      API_ENDPOINTS.RESUME.REQUESTSIGNEDURL,
      fileData
    );
    return response;
  },

  submitForm: async (formData) => {
    const response = await api.post(
      API_ENDPOINTS.RESUME.FORMSUBMISSION,
      formData
    );

    return response;
  },

  getProfile: async (candidateId) => {
    const endpoint = createEndpoint(API_ENDPOINTS.RESUME.GETPROFILE, {
      id: candidateId,
    });
    const response = await api.get(endpoint);

    return response;
  },

  updateProfile: async (candidateId, data) => {
    const endpoint = createEndpoint(API_ENDPOINTS.RESUME.UPDATEPROFILE, {
      id: candidateId,
    });

    const response = await api.put(endpoint, data);
    return response;
  },

  getResumeProfile: async (resumeToken) => {
    const endpoint = createEndpoint(API_ENDPOINTS.RESUME.RESUMEPROFILE, {
      token: resumeToken,
    });
    const response = await api.get(endpoint);

    return response;
  },
};
