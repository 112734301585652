import React from 'react';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Check } from 'lucide-react';
import { FormField } from 'src/components/common/FormField';
import OTPVerificationSection from './OTPVerificationSection';

export const ContactVerification = ({
  register,
  errors,
  contactValue,
  contactVerified,
  isOTPSent,
  isOTPLoading,
  showOTPInput,
  isVerifyingOTP,
  otpError,
  canResend,
  resendTimeout,
  handleSendOTP,
  handleVerifyOTP,
}) => {
  const registerProps = register('contact', {
    required: 'Contact number is required',
    pattern: {
      value: /^[0-9]{10}$/,
      message: 'Invalid contact number',
    },
  });

  return (
    <FormField label="Contact" error={errors.contact?.message} id="contact">
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Input
            id="contact"
            onChange={registerProps.onChange}
            onBlur={registerProps.onBlur}
            name={registerProps.name}
            ref={registerProps.ref}
            className={errors.contact ? 'border-red-500' : ''}
          />
          {contactVerified && (
            <Check className="absolute right-2 top-1/2 -translate-y-1/2 text-green-500 w-5 h-5" />
          )}
        </div>
        <Button
          type="button"
          onClick={() => handleSendOTP('SEND')}
          disabled={
            !contactValue ||
            contactValue.length !== 10 ||
            isOTPSent ||
            isOTPLoading
          }
          className="whitespace-nowrap"
        >
          {isOTPLoading ? (
            <div className="flex items-center">
              <span className="animate-spin h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full" />
              Sending...
            </div>
          ) : (
            'Send OTP'
          )}
        </Button>
      </div>
      {showOTPInput && (
        <OTPVerificationSection
          isVerifyingOTP={isVerifyingOTP}
          handleVerifyOTP={handleVerifyOTP}
          otpError={otpError}
          canResend={canResend}
          resendTimeout={resendTimeout}
          handleSendOTP={handleSendOTP}
          contactVerified={contactVerified}
        />
      )}
    </FormField>
  );
};
