import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';

const MainLayout = () => {
  const setSidebarOpen = false;

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="flex flex-1 flex-col overflow-hidden">
        <Header setSidebarOpen={setSidebarOpen} />
        <main className="flex flex-1 flex-col overflow-y-auto ">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
