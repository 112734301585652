import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Alert, AlertDescription } from 'src/components/ui/alert';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { UserService } from 'src/services/api/UserService';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const authToken = localStorage.getItem('token');
    if (authToken) {
      setToken(authToken);
    }
  }, []);

  const validatePassword = (value) => {
    const hasMinLength = value.length >= 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[@$!%*?&]/.test(value);

    return (
      hasMinLength &&
      hasLowerCase &&
      hasUpperCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);

    // Clear previous errors
    setPasswordError('');
    setConfirmPasswordError('');

    // Validate empty
    if (!value.trim()) {
      setPasswordError('Password is required');
      return;
    }

    // Validate password strength
    if (!validatePassword(value)) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
      );
    }

    // Check if passwords match when confirm password has a value
    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);

    // Clear previous error
    setConfirmPasswordError('');

    // Validate empty
    if (!value.trim()) {
      setConfirmPasswordError('Please confirm your password');
      return;
    }

    // Check if passwords match
    if (value !== password) {
      setConfirmPasswordError('Passwords do not match');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setPasswordError('');
    setConfirmPasswordError('');
    setError('');

    // Validate empty fields
    if (!password.trim() || !confirmPassword.trim()) {
      if (!password.trim()) setPasswordError('Password is required');
      if (!confirmPassword.trim())
        setConfirmPasswordError('Please confirm your password');
      return;
    }

    // Validate password strength
    if (!validatePassword(password)) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
      );
      return;
    }

    // Validate passwords match
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }

    try {
      setIsLoading(true);

      // Call reset password API
      const response = await UserService.resetPassword({
        token,
        newPassword: password,
      });

      if (response.success) {
        navigate('/login', {
          state: {
            message:
              'Password has been reset successfully. Please login with your new password.',
          },
        });
      }
    } catch (err) {
      console.error('Reset password error:', err);
      setError(err.message || 'Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // If no token is present, show error
  if (!token) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
          <Alert variant="destructive">
            <AlertDescription>
              Invalid or expired reset link. Please request a new password
              reset.
            </AlertDescription>
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold mb-2">Change Password</h2>
          <p className="text-gray-600">Please enter your new password</p>
        </div>

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <TooltipProvider>
              <Tooltip open={showPasswordTooltip}>
                <TooltipTrigger asChild>
                  <div>
                    <Input
                      type="password"
                      placeholder="New Password"
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={() => setShowPasswordTooltip(true)}
                      onBlur={() => setShowPasswordTooltip(false)}
                      className={`h-12 ${
                        passwordError ? 'border-red-500' : ''
                      }`}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="p-2">
                  <div className="text-sm">
                    Password must contain:
                    <div className="space-y-1 mt-1">
                      <div>• At least 8 characters</div>
                      <div>• One uppercase letter</div>
                      <div>• One lowercase letter</div>
                      <div>• One number</div>
                      <div>• One special character (@$!%*?&)</div>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {passwordError && (
              <p className="text-sm text-red-500 mt-1">{passwordError}</p>
            )}
          </div>

          <div className="space-y-2">
            <Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={`h-12 ${confirmPasswordError ? 'border-red-500' : ''}`}
            />
            {confirmPasswordError && (
              <p className="text-sm text-red-500 mt-1">
                {confirmPasswordError}
              </p>
            )}
          </div>

          <Button type="submit" className="w-full h-12" disabled={isLoading}>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                Resetting Password...
              </div>
            ) : (
              'Change Password'
            )}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
