import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { FormField } from './common';

export const AddressFields = ({ control, errors }) => (
  <>
    <div className="grid grid-cols-12 gap-x-4 gap-y-6">
      <div className="col-span-12">
        <FormField label="Address Line 1">
          <Controller
            name="addressLine1"
            control={control}
            rules={{
              required: true,
              minLength: 5,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="House/Flat No., Building Name"
                className={`${
                  errors?.addressLine1
                    ? 'border-red-500 focus:border-red-500'
                    : ''
                }`}
              />
            )}
          />
        </FormField>
      </div>
    </div>

    <div className="grid grid-cols-12 gap-x-4 gap-y-6">
      <div className="col-span-12">
        <FormField label="Street">
          <Controller
            name="street"
            control={control}
            rules={{
              required: true,
              minLength: 3,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Street Name"
                className={`${
                  errors?.street ? 'border-red-500 focus:border-red-500' : ''
                }`}
              />
            )}
          />
        </FormField>
      </div>
    </div>

    <div className="grid grid-cols-12 gap-x-4 gap-y-6">
      <div className="col-span-12 md:col-span-4">
        <FormField label="City">
          <Controller
            name="city"
            control={control}
            rules={{
              required: true,
              pattern: /^[A-Za-z\s]+$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter city name"
                className={`${
                  errors?.city ? 'border-red-500 focus:border-red-500' : ''
                }`}
              />
            )}
          />
        </FormField>
      </div>
      <div className="col-span-12 md:col-span-4">
        <FormField label="State">
          <Controller
            name="state"
            control={control}
            rules={{
              required: true,
              pattern: /^[A-Za-z\s]+$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter state name"
                className={`${
                  errors?.state ? 'border-red-500 focus:border-red-500' : ''
                }`}
              />
            )}
          />
        </FormField>
      </div>
      <div className="col-span-12 md:col-span-4">
        <FormField label="ZIP Code">
          <Controller
            name="zipCode"
            control={control}
            rules={{
              required: true,
              pattern: /^[0-9]{6}$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                maxLength={6}
                placeholder="Enter ZIP code"
                className={`${
                  errors?.zipCode ? 'border-red-500 focus:border-red-500' : ''
                }`}
              />
            )}
          />
        </FormField>
      </div>
    </div>
  </>
);
