import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useToast } from 'src/hooks/use-toast';
import { PersonalInfoSection } from '../../components/resume/PersonalInfoSection';
import {
  EducationFields,
  AddEducationButton,
} from '../../components/resume/EducationFields';
import {
  WorkExperienceFields,
  AddExperienceButton,
} from '../../components/resume/WorkExperienceFields';
import { Section, FormActions } from '../../components/resume/common';
import { SkillsSection } from '../../components/resume/SkillsSection';
import { CertificationsSection } from '../../components/resume/CertificationsSection';
import { defaultValues } from '../../components/resume/constants';
import createResumeApi from '../../components/resume/resumeApi';

// Validation schemas
const personalInfoValidation = {
  firstname: { required: 'First name is required' },
  lastname: { required: 'Last name is required' },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address',
    },
  },
  contact: {
    required: 'Phone number is required',
    pattern: {
      value: /^\+?[\d\s-]{10,}$/,
      message: 'Invalid phone number',
    },
  },
  dob: { required: 'Date of birth is required' },
  fathersname: { required: "Father's name is required" },
  addressLine1: { required: 'Address is required' },
  city: { required: 'City is required' },
  state: { required: 'State is required' },
  zipCode: { required: 'ZIP code is required' },
};

const educationValidation = {
  'educations.*.institution': { required: 'Institution name is required' },
  'educations.*.qualification': { required: 'Qualification is required' },
  'educations.*.graduationYear': { required: 'Graduation year is required' },
  'educations.*.fieldsOfStudy': { required: 'Field of study is required' },
};

// const workExperienceValidation = {
//   'workExperiences.*.company': { required: 'Company name is required' },
//   'workExperiences.*.role': { required: 'Role is required' },
//   'workExperiences.*.employment_period.startMonth': {
//     required: 'Start month is required',
//   },
//   'workExperiences.*.employment_period.startYear': {
//     required: 'Start year is required',
//   },
//   'workExperiences.*.employment_period.endMonth': {
//     required: 'End month is required',
//   },
//   'workExperiences.*.employment_period.endYear': {
//     required: 'End year is required',
//   },
// };
const getUserId = () => {
  try {
    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      return userData?.cid;
    }
    return null;
  } catch (error) {
    console.error('Error getting user ID:', error);
    return null;
  }
};

export function SubmitResume() {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(defaultValues);
  const [masterData, setMasterData] = useState({
    skills: [],
    certifications: [],
    fieldsofstudies: [],
    fieldsofstudieshsc: [],
  });

  const resumeApi = React.useMemo(() => {
    const candidateId = getUserId();
    if (!candidateId) {
      return null;
    }
    return createResumeApi({
      isProtected: true,
      candidateId: parseInt(candidateId, 10),
    });
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: location.state?.formData || defaultValues,
    mode: 'onBlur',
    rules: {
      ...personalInfoValidation,
      ...educationValidation,
    },
  });

  useEffect(() => {
    // Check if resumeApi exists
    if (!resumeApi) {
      setIsLoading(false);
      toast({
        title: 'Authentication required',
        description: 'Please log in to continue',
        variant: 'destructive',
      });
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const mappedData = await resumeApi.fetchResumeData();
        console.log('Mapped data:', mappedData);

        setFormData(mappedData);
        setMasterData(
          mappedData.masterData || {
            skills: [],
            certifications: [],
            fieldsofstudies: [],
            fieldsofstudieshsc: [],
          }
        );
        reset(mappedData);
      } catch (error) {
        console.error('Error in fetchData:', error);
        setFormData(defaultValues);
        setMasterData({
          skills: [],
          certifications: [],
          fieldsofstudies: [],
          fieldsofstudieshsc: [],
        });
        reset(defaultValues);

        toast({
          title: 'Error loading resume data',
          description: error.message || 'Please try again later',
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [resumeApi, reset, toast, navigate]);

  const onSubmit = async (data) => {
    if (!resumeApi) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to continue',
        variant: 'destructive',
      });
      navigate('/login');
      return;
    }

    try {
      await resumeApi.updateResume(data);
      toast({
        title: 'Resume updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error updating resume',
        description: error.message || 'Please try again later',
        variant: 'destructive',
      });
    }
  };

  const addEducation = () => {
    const educations = [...(formData.educations || [])];
    educations.push({
      ...defaultValues.educations[0],
      gradeType: 'MARKS', // Set default grade type
    });
    setFormData({ ...formData, educations });
  };

  const addWorkExperience = () => {
    const workExperiences = [...(formData.workExperiences || [])];
    workExperiences.push({ ...defaultValues.workExperiences[0] });
    setFormData({ ...formData, workExperiences });
  };

  const removeEducation = (index) => {
    const educations = formData.educations.filter((_, i) => i !== index);
    if (educations.length === 0) {
      educations.push({ ...defaultValues.educations[0] });
    }
    setFormData({ ...formData, educations });
  };

  const removeWorkExperience = (index) => {
    const workExperiences = formData.workExperiences.filter(
      (_, i) => i !== index
    );
    if (workExperiences.length === 0) {
      workExperiences.push({ ...defaultValues.workExperiences[0] });
    }
    setFormData({ ...formData, workExperiences });
  };

  const generateEducationKey = (edu, index) =>
    `education-${edu.institution || ''}-${edu.graduationYear || ''}-${index}`;

  const generateWorkExperienceKey = (exp, index) =>
    `work-${exp.company || ''}-${exp.role || ''}-${index}`;

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <p className="mt-4 text-sm text-gray-600">Loading resume data...</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center bg-gray-100">
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-2xl font-bold mb-6 text-left">My Resume</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 text-left">
          <PersonalInfoSection control={control} errors={errors} />

          <Section title="Educational Details">
            <div className="space-y-6">
              {(formData.educations || []).map((edu, index) => (
                <EducationFields
                  key={generateEducationKey(edu, index)}
                  education={edu}
                  index={index}
                  control={control}
                  watch={watch}
                  setValue={setValue} // Pass setValue here
                  errors={errors}
                  masterData={masterData}
                  onRemove={
                    (formData.educations || []).length > 1
                      ? () => removeEducation(index)
                      : null
                  }
                />
              ))}
              <AddEducationButton onClick={addEducation} />
            </div>
          </Section>

          <Section title="Work Experience">
            <div className="space-y-6">
              {(formData.workExperiences || []).map((exp, index) => (
                <WorkExperienceFields
                  key={generateWorkExperienceKey(exp, index)}
                  experience={exp}
                  index={index}
                  control={control}
                  errors={errors}
                  onRemove={
                    (formData.workExperiences || []).length > 1
                      ? () => removeWorkExperience(index)
                      : null
                  }
                />
              ))}
              <AddExperienceButton onClick={addWorkExperience} />
            </div>
          </Section>

          <SkillsSection control={control} masterData={masterData} />

          <CertificationsSection control={control} masterData={masterData} />

          <FormActions isSubmitting={isSubmitting} />
        </form>
      </div>
    </div>
  );
}
