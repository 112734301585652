import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { PlusIcon } from 'lucide-react';
import { MonthYearRangePicker } from 'src/components/common/MonthYearRangePicker';
import { FormField } from 'src/components/common/FormField';

const isValidDateRange = (startMonth, startYear, endMonth, endYear) => {
  if (!startMonth || !startYear || !endMonth || !endYear) return true;

  const startDate = new Date(`${startYear}-${startMonth}-01`);
  const endDate = new Date(`${endYear}-${endMonth}-01`);

  return startDate <= endDate;
};

export const WorkExperienceFields = ({ index, control, onRemove, errors }) => {
  const employmentPeriod = useWatch({
    control,
    name: `workExperiences.${index}.employment_period`,
    defaultValue: {},
  });

  const handleEmploymentPeriodChange = (onChange, newValue) => {
    const { startMonth, startYear, endMonth, endYear } = newValue;

    if ((startMonth || startYear) && endMonth && endYear) {
      const isValid = isValidDateRange(
        startMonth || employmentPeriod.startMonth,
        startYear || employmentPeriod.startYear,
        endMonth,
        endYear
      );

      if (!isValid) {
        onChange({
          ...newValue,
          endMonth: '',
          endYear: '',
        });
        return;
      }
    }

    onChange(newValue);
  };

  return (
    <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50">
      <div className="space-y-1">
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12">
            <FormField label="Company Name">
              <Controller
                name={`workExperiences.${index}.company`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    className={`${
                      errors?.workExperiences?.[index]?.company
                        ? 'border-red-500 focus:border-red-500'
                        : ''
                    }`}
                    placeholder="Enter company name"
                  />
                )}
              />
            </FormField>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-4 gap-y-1">
          <div className="col-span-12 md:col-span-6">
            <FormField label="Role">
              <Controller
                name={`workExperiences.${index}.role`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    className={`${
                      errors?.workExperiences?.[index]?.role
                        ? 'border-red-500 focus:border-red-500'
                        : ''
                    }`}
                    placeholder="Enter your role"
                  />
                )}
              />
            </FormField>
          </div>
          <div className="col-span-12 md:col-span-6">
            <FormField label="Employment Period">
              <Controller
                name={`workExperiences.${index}.employment_period`}
                control={control}
                rules={{
                  // required: 'Employment period is required',
                  validate: (value) => {
                    if (
                      !value.startMonth ||
                      !value.startYear ||
                      !value.endMonth ||
                      !value.endYear
                    ) {
                      return 'All date fields are required';
                    }
                    return (
                      isValidDateRange(
                        value.startMonth,
                        value.startYear,
                        value.endMonth,
                        value.endYear
                      ) || 'End date must be after start date'
                    );
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="space-y-1">
                    <MonthYearRangePicker
                      onChange={(newValue) =>
                        handleEmploymentPeriodChange(onChange, newValue)
                      }
                      value={value}
                      // className={`${
                      //   errors?.workExperiences?.[index]?.employment_period
                      //     ? 'border-red-500 focus:border-red-500'
                      //     : ''
                      // }`}
                    />
                    {/* {errors?.workExperiences?.[index]?.employment_period && (
                      <p className="text-sm text-red-500">
                        {
                          errors.workExperiences[index].employment_period
                            .message
                        }
                      </p>
                    )} */}
                  </div>
                )}
              />
            </FormField>
          </div>
        </div>

        {onRemove && (
          <div className="flex justify-end mt-1">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={onRemove}
              className="text-red-500 border-red-500 hover:bg-red-50"
            >
              Remove Experience
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const AddExperienceButton = ({ onClick }) => (
  <Button
    type="button"
    onClick={onClick}
    variant="default"
    size="sm"
    className="flex items-center gap-2"
  >
    <PlusIcon className="h-4 w-4" />
    Add Work Experience
  </Button>
);
