import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { Calendar } from 'lucide-react';
import { format, subYears } from 'date-fns';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { Calendar as CalendarComponent } from 'src/components/ui/calendar';
import { FormField, Section } from './common';
import { AddressFields } from './AddressFields';

const DatePickerWithYear = ({ field, error }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  // Parse the date string in DD/MM/YYYY format
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // month is 0-based
  };

  // Format date to DD/MM/YYYY
  const formatDate = (date) => {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy');
  };

  const defaultMonth = field.value
    ? parseDate(field.value)
    : new Date(currentYear - 18, 0, 1); // 0 represents January

  const today = new Date();
  const hundredYearsAgo = subYears(today, 100);
  const eighteenYearsAgo = subYears(today, 18);

  const handleSelect = (date) => {
    field.onChange(formatDate(date)); // Store date as DD/MM/YYYY string
    setCalendarOpen(false);
  };

  return (
    <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'w-full justify-start text-left font-normal',
            !field.value && 'text-muted-foreground',
            error && 'border-red-500 focus:border-red-500'
          )}
        >
          <Calendar className="mr-2 h-4 w-4" />
          {field.value ? field.value : <span>Pick your date of birth</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <CalendarComponent
          mode="single"
          selected={field.value ? parseDate(field.value) : undefined}
          onSelect={handleSelect}
          disabled={
            (date) =>
              date > eighteenYearsAgo || // Cannot select dates less than 18 years ago
              date < hundredYearsAgo // Cannot select dates more than 100 years ago
          }
          initialFocus
          defaultMonth={defaultMonth}
          fromYear={currentYear - 100} // Earliest selectable year
          toYear={currentYear - 18} // Latest selectable year
          captionLayout="dropdown-buttons"
          classNames={{
            caption_label: 'hidden',
            dropdown_month: 'flex justify-center text-sm font-medium',
            dropdown_year: 'flex justify-center text-sm font-medium',
            dropdown_icon: 'hidden',
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
export const PersonalInfoSection = ({ control, errors }) => (
  <Section title="Personal Information">
    <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50">
      {/* Name Fields */}
      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
        <div className="col-span-12 md:col-span-4">
          <FormField label="First Name" id="firstname">
            <Controller
              name="firstname"
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Za-z\s]+$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`${
                    errors?.firstname
                      ? 'border-red-500 focus:border-red-500'
                      : ''
                  }`}
                  placeholder="Enter your first name"
                />
              )}
            />
          </FormField>
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormField label="Middle Name" id="middlename">
            <Controller
              name="middlename"
              control={control}
              rules={{
                required: false,
                pattern: /^[A-Za-z\s]*$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`${
                    errors?.middlename
                      ? 'border-red-500 focus:border-red-500'
                      : ''
                  }`}
                  placeholder="Enter your middle name"
                />
              )}
            />
          </FormField>
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormField label="Last Name" id="lastname">
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Za-z\s]+$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`${
                    errors?.lastname
                      ? 'border-red-500 focus:border-red-500'
                      : ''
                  }`}
                  placeholder="Enter your last name"
                />
              )}
            />
          </FormField>
        </div>
      </div>

      {/* Contact Details */}
      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
        <div className="col-span-12 md:col-span-6">
          <FormField label="Email">
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  className={`${
                    errors?.email ? 'border-red-500 focus:border-red-500' : ''
                  }`}
                  placeholder="Enter your email"
                />
              )}
            />
          </FormField>
        </div>
        <div className="col-span-12 md:col-span-6">
          <FormField label="Contact">
            <Controller
              name="contact"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9]{10}$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className={`${
                    errors?.contact ? 'border-red-500 focus:border-red-500' : ''
                  }`}
                  placeholder="Enter your contact number"
                />
              )}
            />
          </FormField>
        </div>
      </div>

      {/* Personal Details */}
      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
        <div className="col-span-12 md:col-span-6">
          <FormField label="Date of Birth">
            <Controller
              name="dob"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const date = new Date(value);
                  const today = new Date();
                  const minAge = subYears(today, 18); // Must be at least 18 years old
                  const maxAge = subYears(today, 100); // Maximum age 100 years

                  if (date > minAge) {
                    return 'You must be at least 18 years old';
                  }
                  if (date < maxAge) {
                    return 'Date cannot be more than 100 years ago';
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <DatePickerWithYear
                  field={field}
                  error={errors?.dob}
                  maxDate={subYears(new Date(), 18)} // Restrict future date selection to 18 years ago
                />
              )}
            />
          </FormField>
        </div>
        <div className="col-span-12 md:col-span-6">
          <FormField label="Father's Name">
            <Controller
              name="fathersname"
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Za-z\s]+$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`${
                    errors?.fathersname
                      ? 'border-red-500 focus:border-red-500'
                      : ''
                  }`}
                  placeholder="Enter your father's name"
                />
              )}
            />
          </FormField>
        </div>
      </div>

      {/* Address */}
      <AddressFields control={control} errors={errors} />
    </div>
  </Section>
);
